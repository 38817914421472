import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link as ScrollLink } from "react-scroll";  // Importing Link as ScrollLink from react-scroll
import { Link as RouterLink, useLocation } from "react-router-dom";  // Importing Link as RouterLink from react-router-dom
import LogoIcon from "../../assets/svg/Logo";
import BurgerIcon from "../../assets/svg/BurgerIcon";

export default function TopNavbar({ toggleSidebar }) {
  const [y, setY] = useState(window.scrollY);
  const [procurementDropdownOpen, setProcurementDropdownOpen] = useState(false);
  const [organisationDropdownOpen, setOrganisationDropdownOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 760);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => setY(window.scrollY);
    const handleResize = () => setIsSmallScreen(window.innerWidth <= 760);

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [y]);

  useEffect(() => {
    // Close dropdowns when the page changes
    setProcurementDropdownOpen(false);
    setOrganisationDropdownOpen(false);
  }, [location]);

  useEffect(() => {
    // Close dropdowns when clicking outside
    const handleClickOutside = (event) => {
      if (
        procurementDropdownOpen && !event.target.closest(".dropdown-container") &&
        organisationDropdownOpen && !event.target.closest(".dropdown-container")
      ) {
        setProcurementDropdownOpen(false);
        setOrganisationDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [procurementDropdownOpen, organisationDropdownOpen]);  

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
    toggleSidebar(); // Call the toggleSidebar function passed from parent component
  };

  const toggleProcurementDropdown = () => {
    setProcurementDropdownOpen(!procurementDropdownOpen);
    setOrganisationDropdownOpen(false); // Close other dropdown
  };
  
  const toggleOrganisationDropdown = () => {
    setOrganisationDropdownOpen(!organisationDropdownOpen);
    setProcurementDropdownOpen(false); // Close other dropdown
  };
  

  return (
    <Wrapper className="flexCenter animate whiteBg" style={y > 100 ? { height: "60px" } : { height: "80px" }}>
      <NavInner className="container flexSpaceCenter">
        <RouterLink to="/" className="pointer flexNullCenter">
          <LogoIcon />
          <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
            GPTF
          </h1>
        </RouterLink>
        {isSmallScreen && (
          <BurgerWrapper className="pointer" onClick={handleSidebarToggle}>
            <BurgerIcon />
          </BurgerWrapper>
        )}
        <UlWrapper className="flexNullCenter">
          <NavItem>
            <NavLink to="/" as={RouterLink}>Home</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="about-us" as={location.pathname === "/" ? ScrollLink : RouterLink} spy={true} smooth={true} offset={-80}>About</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="projects" as={location.pathname === "/" ? ScrollLink : RouterLink} spy={true} smooth={true} offset={-80}>Projects</NavLink>
          </NavItem>
          <DropdownItem>
            <DropdownLink onClick={toggleOrganisationDropdown}>
              Organization
              <DropdownIcon className={organisationDropdownOpen ? "active" : ""}>&#9662;</DropdownIcon>
            </DropdownLink>
            {organisationDropdownOpen && (
              <DropdownContent>
                <SubDropdownItem>
                  <SubDropdownLink as={RouterLink} to="/organization-management">Management</SubDropdownLink>
                </SubDropdownItem>
                <SubDropdownItem>
                  <SubDropdownLink as={location.pathname === "/" ? ScrollLink : RouterLink} to="funding" spy={true} smooth={true} offset={-80}>Funding</SubDropdownLink>
                </SubDropdownItem>
              </DropdownContent>
            )}
          </DropdownItem>

          <DropdownItem>
            <DropdownLink onClick={toggleProcurementDropdown}>
              Procurement
              <DropdownIcon className={procurementDropdownOpen ? "active" : ""}>&#9662;</DropdownIcon>
            </DropdownLink>
            {procurementDropdownOpen && (
              <DropdownContent>
                <SubDropdownItem>
                  <SubDropdownLink as={RouterLink} to="/procurement-bids">Bids</SubDropdownLink>
                </SubDropdownItem>
                <SubDropdownItem>
                  <SubDropdownLink as={RouterLink} to="/procurement-awards">Bid Awards</SubDropdownLink>
                </SubDropdownItem>
              </DropdownContent>
            )}
          </DropdownItem>
          <NavItem>
            <NavLink to="/blogs" as={RouterLink}>Blog</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="contact" as={location.pathname === "/contact" ? ScrollLink : RouterLink} spy={true} smooth={true} offset={-80}>Contact</NavLink>
          </NavItem>
        </UlWrapper>
        <UlWrapperRight className="flexNullCenter">
          <NavItem>
            <a href="/contact" className="radius8 lightBg" style={{ padding: "10px 15px" }}>TALK TO US</a>
          </NavItem>
        </UlWrapperRight>
      </NavInner>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
`
const BurgerWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;

// Other styled components...

const NavItem = styled.li`
  position: relative;
`;

const NavLink = styled(ScrollLink)`  // Use ScrollLink for in-page navigation
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
`;

const DropdownItem = styled(NavItem)`
  position: relative;
`;

const DropdownLink = styled.span`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
`;

const DropdownIcon = styled.span`
  margin-left: 5px;
  transition: transform 0.3s ease-in-out;
  &.active {
    transform: rotateX(180deg);
  }
`;

const DropdownContent = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
`;

const SubDropdownItem = styled(NavItem)`
  padding: 10px 15px;
`;

const SubDropdownLink = styled(RouterLink)`  // Use RouterLink for route-based navigation
  display: block;
  color: #333;
  text-decoration: none;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #f9f9f;
  }
`;
