import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export default function BlogBox({ id, title, text, tag, author, imageUrl }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/article/${id}`);
  };

  return (
    <Wrapper onClick={handleClick}>
      <ImgWrapper>
        <img 
          src={imageUrl || "https://via.placeholder.com/400x200"} 
          alt={title || "Blog post"} 
        />
      </ImgWrapper>
      <div className="blog-content" style={{ padding: "20px" }}>
        <h3>{title}</h3>
        <p>{text?.substring(0, 60)}</p>
        <div className="blog-footer">
          <span>{tag}</span>
          <span>{author}</span>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  height: 200px;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;