import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import BlogBox from "components/Elements/BlogBox";
import { getArticles } from 'store/actions/cmsActions';
import Footer from "../components/Sections/Footer";

const FullBlogPage = () => {
  const dispatch = useDispatch();
  const { articles } = useSelector((state) => state.cmsReducer);
  const organizationId = 'OfQa6MDeh3aGmboGnbQJ';

  useEffect(() => {
    dispatch(getArticles(organizationId));
  }, [dispatch]);


  return (
    <> 
      <Wrapper>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Game Products Trust Fund News</h1>
            <p className="font13">
              Explore our latest articles on wildlife conservation, sustainable resource management, 
              and community development initiatives in Namibia. Stay informed about the Game Products 
              Trust Fund's impact on preserving biodiversity and supporting local communities.
            </p>
          </HeaderInfo>
          <div className="row">
            {articles?.map((article) => (
              <BlogBoxWrapper key={article.id} className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
                <BlogBox
                  id={article.id}
                  title={article.title}
                  text={article.description}
                  tag="Conservation"
                  author="GPTF Namibia"
                  imageUrl={article.imageUrl} // Pass the imageUrl here
                />
              </BlogBoxWrapper>
            ))}
          </div>
        </div>
      </Wrapper>           
    </>
  );
};

const Wrapper = styled.section`
  width: 100%;
  padding: 80px 0;
  margin-top: 50px;
  background-color: #f5f5f5;
  height: 100vh;
`;

const HeaderInfo = styled.div`
  margin-bottom: 50px;
  text-align: center;
  
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const BlogBoxWrapper = styled.div`
  margin-bottom: 30px;

  & > div {
    height: 400px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }

    .blog-content {
      padding: 20px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h3 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
        color: #333;
      }

      p {
        font-size: 14px;
        color: #666;
        margin-bottom: 15px;
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }

      .blog-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: #999;
      }
    }
  }
`;

export default FullBlogPage;