import React from "react";
import styled from "styled-components";
import ClientSlider from "../Elements/ClientSlider";
import { useNavigate } from "react-router-dom";

import AddImage2 from "../../assets/img/add/add2.png";
import AboutUS from "../../assets/img/aboutUs.jpg";
import FullButton from "../Buttons/FullButton";

export default function AboutUs() {
  const navigate = useNavigate();

  return (
    <Advertising id="about" className="flexSpaceCenter">
      <AddLeft>
        <AddLeftInner>
          <ImgWrapper className="flexCenter">
            <img className="radius8" src={AboutUS} alt="add" />
          </ImgWrapper>
        </AddLeftInner>
      </AddLeft>
      <AddRight>
        <h4 className="font15 semiBold">About the GPTF</h4>
        <h2 className="font40 extraBold">Game Products Trust Fund</h2>
        <p className="font12" style={{
          textAlign: 'left',
          width: '100%',
          paddingRight: 46
        }}>
          The Game Products Trust Fund (GPTF) was established through the Game Products Trust Fund Act (Act No. 7 of 1997) as a mechanism for ensuring that revenue obtained from the sale of wildlife products could be used exclusively towards wildlife conservation and community conservation and development programmes aimed at harmonizing the co-existence of people with such wildlife, and thus securing a future for wildlife outside of and within protected areas in Namibia.
          <br /><br />
          <b>Mandate:</b> The GPTF is mandated by an act of parliament to collect revenue from wildlife and wildlife products recovered on state land and reinvest it into wildlife conservation, communal conservation and rural development programmes in Namibia.
          <br /><br />
          <b>Mission:</b> To reinvest funds from Wildlife use and other sources into sustainable conservation- and rural development programmes, in Namibia.
          <br /><br />
          <b>Vision:</b> To be a catalyst for sustainable wildlife management practices while improving livelihoods of those sharing their land with wildlife.
        </p>
        <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
          <div style={{ width: "190px" }}>
            <FullButton title="Learn More" action={() => navigate("/about-us")} border />
          </div>
          <div style={{ width: "190px", marginLeft: "15px" }}>
            <FullButton title="Contact Us" action={() => alert("clicked")} border />
          </div>
        </ButtonsRow>
      </AddRight>
    </Advertising>
  );
}

const Advertising = styled.div`
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;

const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;

const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;

const AddLeftInner = styled.div`
  width: 100%;
  position: static;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;

const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;


