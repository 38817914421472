// src/components/Organization.js

import React from "react";
import styled from "styled-components";
import { teamMembers } from "../../data/teamData";

const Organization = () => {
  return (
    <Wrapper>
      <div className="heading">
        <h2 className="text-center bottom-line">Meet Our Team</h2>
        <p className="subheading text-center">The team that is responsible for all the exceptoinal work done by GPTF.</p>
      </div>
      <div className="team-row">
        {teamMembers.map((member, index) => (
          <div key={index} className="team-wrap">
            <div className="team-member text-center">
              <div className="team-img">
                <img src={member.image} alt={member.name} />
                <div className="overlay">
                  <div className="team-details text-center">
                    <p>{member.description}</p>
                    <div className="socials mt-20">
                      <a href={member.socials.facebook}><i className="fa fa-facebook"></i></a>
                      <a href={member.socials.twitter}><i className="fa fa-twitter"></i></a>
                      <a href={member.socials.googlePlus}><i className="fa fa-google-plus"></i></a>
                      <a href={member.socials.email}><i className="fa fa-envelope"></i></a>
                    </div>
                  </div>
                </div>
              </div>
              <h6 className="team-title">{member.name}</h6>
              <span>{member.role}</span>
            </div>
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

// Styled Components for the Organization Component
const Wrapper = styled.div`
  .heading {
    text-align: center;
    margin-bottom: 30px;

    h2 {
      font-size: 2.5rem;
      color: #333;
    }

    .subheading {
      font-size: 1.2rem;
      color: #9bbb59;
      margin-bottom: 10px;
    }
  }

  .team-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .team-wrap {
    width: 300px;
    margin: 20px;
    text-align: center;
  }

  .team-member {
    position: relative;
    overflow: hidden;
    background-color: transparent;
    border-radius: 8px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }

  .team-img {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 350px; /* Adjust height as needed */
    background-color: #f1f1f1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.2s ease-in-out;
    }

    .overlay {
      background: linear-gradient(to top, rgba(20, 20, 20, 0.7) 0%, rgba(20, 20, 20, 0) 60%);
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 60%;
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }

    .team-details {
      opacity: 0;
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 10%;
      transition: all 0.2s ease-in-out;

      p {
        color: #fff;
      }

      .socials a {
        margin: 0 5px;
        color: white;
        font-size: 1.2rem;
        transition: color 0.3s;

        &:hover {
          color: #0794c9;
        }
      }
    }

    &:hover .overlay,
    &:hover .team-details {
      opacity: 1;
    }
  }

  .team-title {
    margin-top: 15px;
    font-size: 1.5rem;
    color: #333;
  }

  span {
    font-size: 1rem;
    color: #9bbb59;
  }
`;

export default Organization;
