import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Footer from "../components/Sections/Footer";

// Image imports (placeholder URLs used here)
import HeroImage from "../../src/assets/img/about-us-header.avif";
import Initiative1 from "../../src/assets/img/initiative-1.avif";
import Initiative2 from "../../src/assets/img/initative-2.avif";
import Award1 from "../../src/assets/img/award.avif";
import Award2 from "../../src/assets/img/award.avif";
import WhoWeAreImg from "../../src/assets/img/about-us-history.avif";

const AboutUsPage = () => {
  return (
    <>  
      <Wrapper>
        <HeroSection>
          <HeroContent>
            <h1>About the GPTF</h1>
            <p>Game Products Trust Fund</p>
          </HeroContent>
        </HeroSection>

        <AboutSection>
          <div className="container">
            <div className="row">
              <div className="content-column col-lg-6 col-md-12 col-sm-12 order-2">
                <div className="inner-column">
                  <div className="sec-title">
                    <span className="title">Our Story</span>
                    <h2>Who We Are</h2>
                  </div>
                  <div className="text">
                    The Game Products Trust Fund (GPTF) was established through the Game Products Trust Fund Act (Act No. 7 of 1997) to ensure revenue from the sale of wildlife products is used exclusively towards wildlife conservation and community development.
                  </div>
                  <div className="text">
                    We aim to harmonize the co-existence of people and wildlife, securing a future for wildlife both within and outside of protected areas in Namibia.
                  </div>
                  <div className="btn-box">
                    <a href="#" className="theme-btn btn-style-one">Learn More</a>
                  </div>
                </div>
              </div>

              <div className="image-column col-lg-6 col-md-12 col-sm-12">
                <div className="inner-column">
                  <figure className="image-1">
                    <img src={WhoWeAreImg} alt="Our Story" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </AboutSection>

        <Section>
          <div className="sec-title">
            <span className="title">Mission</span>
            <h2>Our Mission</h2>
          </div>
          <div className="text">
            To reinvest funds from wildlife use into sustainable conservation and rural development programs in Namibia.
          </div>
          <div className="sec-title">
            <span className="title">Vision</span>
            <h2>Our Vision</h2>
          </div>
          <div className="text">
            To be a catalyst for sustainable wildlife management practices while improving the livelihoods of those sharing their land with wildlife.
          </div>
        </Section>

        <AboutSection>
          <div className="container">
            <div className="row">
              <div className="content-column col-lg-6 col-md-12 col-sm-12">
                <div className="inner-column">
                  <div className="sec-title">
                    <span className="title">Initiatives</span>
                    <h2>Our Key Initiatives</h2>
                  </div>
                  <ul className="list-style-one">
                    <li>Wildlife Conservation - Protecting Namibia's diverse wildlife.</li>
                    <li>Community Development - Empowering local communities through sustainable practices.</li>
                  </ul>
                  <div className="btn-box">
                    <a href="#" className="theme-btn btn-style-one">Support Us</a>
                  </div>
                </div>
              </div>
              <div className="image-column col-lg-6 col-md-12 col-sm-12">
                <div className="inner-column">
                  <figure className="image-1">
                    <img src={Initiative1} alt="Initiative 1" />
                  </figure>
                  <figure className="image-2">
                    <img src={Initiative2} alt="Initiative 2" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </AboutSection>

        <AwardsSection>
          <div className="sec-title">
            <span className="title">Awards and Recognition</span>
            <h2>Our Achievements</h2>
          </div>
          <div className="awards-grid">
            <div className="award">
              <img src={Award1} alt="Award 1" />
              <h3>Conservation Excellence Award</h3>
              <p>Recognized for outstanding efforts in wildlife preservation.</p>
            </div>
            <div className="award">
              <img src={Award2} alt="Award 2" />
              <h3>Community Impact Award</h3>
              <p>Honored for significant contributions to rural development.</p>
            </div>
          </div>
        </AwardsSection>

        <CTASection>
          <h2>Join Our Mission</h2>
          <p>Help us create a sustainable future for wildlife and communities.</p>
          <Button as={Link} to="/contact">Get Involved</Button>
        </CTASection>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  background-color: #f5f5f5;
  color: #333;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
`;

const HeroSection = styled.div`
  background-image: url(${HeroImage});
  background-size: cover;
  background-position: center;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
`;

const HeroContent = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  padding: 2rem;
  border-radius: 8px;

  h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.5rem;
  }
`;

const AboutSection = styled.section`
  padding: 60px 0;

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .content-column {
    flex: 1;
    padding: 15px;
  }

  .image-column {
    flex: 1;
    padding: 15px;
  }

  .sec-title {
    margin-bottom: 30px;
    color: #9bbb59;

    .title {
      font-size: 1.2rem;
      color: #9bbb59;
      margin-bottom: 10px;
    }

    h2 {
      font-size: 2.5rem;
      margin-bottom: 15px;
    }
  }

  .text {
    margin-bottom: 20px;
    font-size: 1rem;
    color: #555;
  }

  .btn-box {
    margin-top: 20px;
  }

  .theme-btn {
    padding: 10px 20px;
    background-color: #9bbb59;
    color: white;
    text-transform: uppercase;
    border-radius: 5px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0794c9;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
    max-height: 300px; /* Adjust max height as needed */
  }
`;

const Section = styled.section`
  padding: 60px 0;
  background-color: #fff;
  text-align: center;

  .sec-title {
    margin-bottom: 30px;

    .title {
      font-size: 1.2rem;
      color: #9bbb59;
      margin-bottom: 10px;
    }

    h2 {
      font-size: 2.5rem;
      margin-bottom: 15px;
    }
  }

  .text {
    max-width: 800px;
    margin: 0 auto 20px;
    font-size: 1rem;
    color: #555;
  }
`;

const AwardsSection = styled.section`
  padding: 60px 0;
  background-color: #f0f0f0;
  text-align: center;

  .sec-title {
    margin-bottom: 30px;

    .title {
      font-size: 1.2rem;
      color: #9bbb59;
      margin-bottom: 10px;
    }

    h2 {
      font-size: 2.5rem;
      margin-bottom: 15px;
    }
  }

  .awards-grid {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }

  .award {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    img {
      max-width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 8px;
      max-height: 150px; /* Adjust max height as needed */
    }

    h3 {
      margin-top: 20px;
      font-size: 1.5rem;
      color: #333;
    }

    p {
      margin-top: 10px;
      font-size: 1rem;
      color: #777;
    }
  }
`;

const CTASection = styled.section`
  padding: 38px 20px;
  background-color: #9bbb59;
  text-align: center;
  color: white;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 15px;
  }

  p {
    max-width: 800px;
    margin: 0 auto 20px;
    font-size: 1rem;
    color: white;
  }
`;

const Button = styled(Link)`
  padding: 10px 20px;
  background-color: white;
  color: #0794c9;
  text-transform: uppercase;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  text-decoration: none;

  &:hover {
    background-color: #e0e0e0;
  }
`;


export default AboutUsPage;
