// src/components/FeedbackButton.js

import React from 'react';
import styled from 'styled-components';

const FeedbackButton = ({ onClick, text, description }) => {
  return (
    <ButtonWrapper>
      <StyledButton onClick={onClick}>{text}</StyledButton>
      <Description>{description}</Description>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledButton = styled.button`
  background-color: #9bbb59;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  font-size: 1em;
  transition: all 0.3s ease;

  &:hover {
    background-color: #006666;
    transform: translateY(-2px);
  }
`;

const Description = styled.p`
  margin-top: 10px;
  text-align: center;
  font-size: 0.9em;
  color: #666;
`;

export default FeedbackButton;