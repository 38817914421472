import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ProjectBox from "components/Elements/ProjectBox";
import FullButton from "components/Buttons/FullButton";
import { getProjects } from "store/actions/cmsActions";

 

const ProjectsPage = () => {
  const navigate = useNavigate();
  const organizationId = 'OfQa6MDeh3aGmboGnbQJ';

  const handleViewAll = () => {
    navigate("/projects");
  };

  const dispatch = useDispatch();
  const { projects } = useSelector((state) => state.cmsReducer);

  const projectList = projects?.map((project, i) => {
    console.log("getProjects", project)
    return(
      <div key={i} className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
        <ProjectBox
          id={project?.id}
          title={project?.title}
          description={project?.description}
          tag="Community"
          author="GPTF"
          img={project?.imageUrl}
        />
      </div>
    )
  });

  useEffect(() => {
    dispatch(getProjects(organizationId));
  }, [dispatch, organizationId]);

  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Our Awesome Projects</h1>
            <p className="font13">
              Explore our range of conservation and community development projects across Namibia.
              These initiatives showcase our commitment to wildlife protection and sustainable resource management.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            {/* <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={Project1}
                title="#Khoadi I /Hoas Conservancy Water Pumping Trailer"
                text="The #Khoadi //Hoas Conservancy decided to install a mobile trailer-mounted dual-powered water pump at the Klip River entrance to supply water infrastructure, storage, and a drinking trough, facilitating service to various conservancy areas."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={Project2}
                title="Installation of water points at Marienfluss Conservancy"
                text="The following pictures show the work that was undertaken to install new water points for the Marienfluss conservancy as a measure to mitigate human-wildlife conflicts."
                action={() => alert("clicked")}
              />
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
              <ProjectBox
                img={Project3}
                title="Wildlife Protection School to Open Soon"
                text="Namibia will soon open its own school of excellence for wildlife protection training at the Waterberg Plateau Park in the Otjozondjupa Region. The centre will train game rangers from across the country."
                action={() => alert("clicked")}
              />
            </div> */}
            {projectList}
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title="View All Projects" action={handleViewAll} />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
`;

const HeaderInfo = styled.div`
  margin-bottom: 50px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

export default ProjectsPage;