import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth"; 
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage"; 
import { getToken, onMessage } from "firebase/messaging";
import { getMessaging } from "firebase/messaging/sw";

 
const firebaseConfig = { 
  	apiKey: "AIzaSyDtMMdm5Mp1IIq-R3S11u8qwwW3BNVq-Ww",
	authDomain: "hermes-e6582.firebaseapp.com",
	databaseURL: "https://hermes-e6582-default-rtdb.firebaseio.com",
	projectId: "hermes-e6582",
	storageBucket: "hermes-e6582.appspot.com",
	messagingSenderId: "409372579973",
	appId: "1:409372579973:web:5024ca1a3facf632dff1ec",
	measurementId: "G-CCVWV69JB7",
};
 
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app)
const rtdb = getDatabase(app);
const messaging = getMessaging(app);

const KEY = "BF8uX_cYoM0rx7VBrNbXWS2ido2KOHSm5yzX1JTjtEqV1QiNGZwVDkoJWrWh90Vq1wfm2k5qq5cg4qgBb8g5tss"
 const getOrRegisterServiceWorker = async () => {
	if ('serviceWorker' in navigator) {
	  return window.navigator.serviceWorker
		.getRegistration('/firebase-push-notification-scope')
		.then((serviceWorker) => {
		  if (serviceWorker) return serviceWorker;
		  return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
			scope: '/firebase-push-notification-scope',
		  });
		});
	}
	throw new Error('The browser doesn`t support service worker.');
  };

export const getCurrentToken = async (setTokenFound) => {
	return getToken(messaging, {vapidKey: KEY, getOrRegisterServiceWorker}).then((currentToken) => {
	  if (currentToken) {
		// console.log('current token for client: ', currentToken);
		setTokenFound(true);
		// Track the token -> client mapping, by sending to backend server
		// show on the UI that permission is secured
	  } else {
		// console.log('No registration token available. Request permission to generate one.');
		setTokenFound(false);
		// shows on the UI that permission is required 
	  }
	}).catch((err) => {
	  console.log('An error occurred while retrieving token. ', err);
	  // catch error while creating client token
	});
  }
  export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
		// console.log('========payload======', payload);
      resolve(payload);
    });
});
 
export { db, auth, rtdb, storage, messaging };

 