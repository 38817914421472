import React, { useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
// Screens
import Landing from "./screens/Landing.jsx";
import Sidebar from "./components/Nav/Sidebar.jsx";
import ProcurementBids from "./screens/ProcurementBids.jsx"; 
import AboutUs from "./components/Sections/AboutUs.jsx";
import Login from "./components/auth/Login.jsx";
import SelectedArticle from "components/ClickedViews/SelectedArticle.jsx";
import SelectedProject from "components/ClickedViews/SelectedProject.jsx";
import ProjectsPage from "screens/ProjectsPage.jsx";

import TopNavbar from "./components/Nav/TopNavbar"; 
import ProcurementAwards from "./screens/ProcurementAwards.jsx";
import Organization from "./screens/OrganizationManagement.jsx";
import BlogPage from "./screens/BlogPage.jsx";
import AboutUsPage from "screens/AboutUsPage.jsx";
import OrganizationManagement from "./screens/OrganizationManagement.jsx";
import ContactUsPage from "screens/ContactUsPage.jsx";
import Footer from "components/Sections/Footer.jsx";
import styled from 'styled-components';



import CS from "./gptf.png";


export default function App() {
  const location = useLocation();
  const isLandingPage = location.pathname === "/";
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div>

      <img style={{height: "100vh"}} src={CS} alt="Initiative 1" />
    </div>
     // <AppWrapper>
    //   <Helmet>
    //     <link rel="preconnect" href="https://fonts.googleapis.com" />
    //     <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    //     <link href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap" rel="stylesheet" />
    //   </Helmet>
    //   <TopNavbar isLandingPage={isLandingPage}  />
    //   <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
    //   <ContentWrapper>
    //     <Routes>
    //       <Route path="/" element={<Landing />} /> 
    //       <Route path="/login" element={<Login />} /> 
    //       <Route path="/procurement-bids" element={<ProcurementBids />} /> 
    //       <Route path="/procurement-awards" element={<ProcurementAwards />} />
    //       <Route path="/organization-management" element={<OrganizationManagement />} />
    //       <Route path="/blogs" element={<BlogPage />} />
    //       <Route path="/article/:id" element={<SelectedArticle />} />
    //       <Route path="/projects" element={<ProjectsPage />} />
    //       <Route path="/project/:id" element={<SelectedProject />} />
    //       <Route path="/about-us" element={<AboutUsPage />} />
    //       <Route path="/contact" element={<ContactUsPage />} />
    //     </Routes>
    //   </ContentWrapper>
    //   <FooterWrapper>
    //     <Footer />
    //   </FooterWrapper>
    // </AppWrapper>
  );
}

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
`;

const FooterWrapper = styled.div`
  width: 100%;
  margin-top: auto;
  background-color: #333;
`;

