import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import ProjectBox from "components/Elements/ProjectBox";
import { getProjects } from "store/actions/cmsActions";
import Footer from "../components/Sections/Footer";

const ProjectsPage = () => {
  const dispatch = useDispatch();
  const { projects } = useSelector((state) => state.cmsReducer);
  const organizationId = 'OfQa6MDeh3aGmboGnbQJ';


  useEffect(() => {
    dispatch(getProjects(organizationId));
  }, [dispatch]);

  return (
    <>
      <Wrapper>
          <WhiteBg>
            <Container>
              <HeaderInfo>
                <Title>Our Awesome Projects</Title>
                <Description>
                  Explore our range of conservation and community development projects across Namibia.
                  These initiatives showcase our commitment to wildlife protection and sustainable resource management.
                </Description>
              </HeaderInfo>
              <ProjectGrid>
                {projects?.map((project, index) => (
                  <ProjectItem key={index}>
                    <ProjectBox
                      id={project.id}
                      img={project.imageUrl}
                      title={project.title}
                      text={project.description}
                      action={() => alert("Project details not yet implemented")}
                    />
                  </ProjectItem>
                ))}
              </ProjectGrid>
            </Container>
          </WhiteBg>
        </Wrapper>  
    </>
  );
};


const Wrapper = styled.section`
  width: 100%;
  marginTop: 32px;
  padding: 50px 0;
  margin-top: 4%;
`;

const WhiteBg = styled.div`
  background-color: white;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  
`;

const HeaderInfo = styled.div`
  // margin-bottom: 50px;
  marginTop: 32px;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 40px;
  font-weight: 400;
  // margin-bottom: 20px;
  marginTop: 32px;
`;

const Description = styled.p`
  // font-size: 16px;
  max-width: 600px;
  margin: 0 auto;
`;

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
`;

const ProjectItem = styled.div`
  width: 100%;
`;

export default ProjectsPage;