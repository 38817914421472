import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Footer from "../components/Sections/Footer";
import { getTenders } from 'store/actions/cmsActions';

const ProcurementAwards = () => {
  const dispatch = useDispatch();
  const { tenders, categories } = useSelector((state) => state.cmsReducer);
  const organizationId = 'OfQa6MDeh3aGmboGnbQJ';

  const [sortBy, setSortBy] = useState("latest");
  const [categoryFilter, setCategoryFilter] = useState("all");

  useEffect(() => {
    dispatch(getTenders(organizationId));
  }, [dispatch]);

  useEffect(() => {
   
  }, [tenders]);

  const getTimeRemaining = (closingDate) => {
    const now = new Date().getTime();
    const distance = closingDate - now;
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    return `${days}d ${hours}h`;
  };

  const sortedAndFilteredTenders = tenders
    ?.filter(tender => categoryFilter === "all" || tender.categories.includes(categoryFilter))
    .filter(tender => tender.status !== "0")  // Only show closed bids
    .sort((a, b) => {
      if (sortBy === "latest") {
        return b.created - a.created;
      } else if (sortBy === "oldest") {
        return a.created - b.created;
      }
    });

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategoryFilter(e.target.value);
  };

  const handleDownload = async (tenderId) => {
    try {
      const tender = tenders.find(t => t.id === tenderId);
      if (tender && tender.documentsLinks && tender.documentsLinks.length > 0) {
        const documentId = tender.documentsLinks[0];
        const response = await fetch(`/api/documents/${documentId}`);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${tender.name}_document.pdf`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        console.error("No document link available for this tender");
      }
    } catch (error) {
      console.error("Error downloading document:", error);
    }
  };

  return (
    <>
      <TopNavbar />
      <Header />
      <Wrapper>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Procurement Awards</h1>
            <p className="font13">This page displays all closed and awarded bids by the Game Products Trust Fund of Namibia (GPTF).</p>
          </HeaderInfo>
          <FilterContainer>
            <FilterOption>
              <FilterLabel htmlFor="sortBy">Sort By:</FilterLabel>
              <SortSelect id="sortBy" value={sortBy} onChange={handleSortChange}>
                <option value="latest">Latest</option>
                <option value="oldest">Oldest</option>
              </SortSelect>
            </FilterOption>
            <FilterOption>
              <FilterLabel htmlFor="categoryFilter">Category:</FilterLabel>
              <CategorySelect
                id="categoryFilter"
                value={categoryFilter}
                onChange={handleCategoryChange}
              >
                <option value="all">All Categories</option>
                {
                  categories?.map(category => <option key={category?.id} value={category?.id}>{category?.name}</option>)
                } 
              </CategorySelect>
            </FilterOption>
          </FilterContainer>
          <TenderList>
            {sortedAndFilteredTenders?.map((tender) => {

                const category = categories?.find((el) => el.id === tender.categories[0]);
 

              return (<TenderItem key={tender.id}>
                <TenderTitle>{tender.name}</TenderTitle>
                <TenderInfo>
                  {!category ?<span style={{ color: '#fff', padding:'2px 4px', borderRadius: 6, background: "#7620ff" }}> 
                    General
                  </span> : <span style={{ color: '#fff', padding:'2px 4px', borderRadius: 6, background: "#7620ff" }}> {category?.name}</span>}
                  <div>
                  <span style={{ color: '#333' }}>Closing Date: {new Date(tender.date[1] * 1000).toLocaleDateString()}, </span>
                  <span style={{ color: '#9bbb59' }}>Time Remaining: {getTimeRemaining(tender.date[1] * 1000)}</span>
                  </div>
                </TenderInfo>
                <TenderDescription>{tender.description}</TenderDescription>
                <ButtonContainer>
                  <DownloadButton onClick={() => handleDownload(tender.id)}>Download Bid Document</DownloadButton>
                  <StatusButton status={tender.status}>
                    {tender.status === "0" ? 'Open' : 'Closed'}
                    <Tooltip>{tender.status === "0" ? 'This tender is open.' : 'This tender is closed.'}</Tooltip>
                  </StatusButton>
                </ButtonContainer>
              </TenderItem>)
          })}
          </TenderList>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.section`
  width: 100%;
  padding: 30px 0;
  background: #e4e7e9;
  min-height: 100vh;
`;

const HeaderInfo = styled.div`
  margin-bottom: 50px;
  text-align: center;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
  border-radius: 12px;
  padding: 10px;
  background-color: #f0f4f8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const FilterOption = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const FilterLabel = styled.label`
  font-weight: 600;
  color: #333;
`;

const SortSelect = styled.select`
  padding: 8px 12px;
  border: none;
  background-color: #ffffff;
  font-size: 16px;
  color: #333;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e6e9ed;
  }
`;

const CategorySelect = styled.select`
  padding: 8px 12px;
  border: none;
  background-color: #ffffff;
  font-size: 16px;
  color: #333;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e6e9ed;
  }
`;

const TenderList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TenderItem = styled.div`
  background: ${props => props.status === "0" ? '#ffffff' : '#f0f4f8'};
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const TenderTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #333;
`;

const TenderInfo = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #9bbb59;
  margin-bottom: 10px;
`;

const TenderDescription = styled.p`
  font-size: 16px;
  color: #555;
  margin-bottom: 15px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DownloadButton = styled.button`
  background-color: #4a90e2;
  color: #fff;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #9bbb59;
    transform: scale(1.05);
  }
`;

const StatusButton = styled.button`
  background-color: ${props => props.status === "0" ? '#9bbb59' : '#f28e8c'};
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: ${props => props.status === "0" ? '#218838' : '#e06d6d'};
    transform: scale(1.05);
  }

  &:hover .tooltip {
    opacity: 1;
    visibility: visible;
  }
`;

const Tooltip = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 1;
`;

export default ProcurementAwards;
