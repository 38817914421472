import React from "react";
import styled from "styled-components";

const ErrorComponent = ({ message }) => {
  console.error("Error:", message); // Logging the error
  return (
    <ErrorContainer>
      <ErrorMessage>Oops! Something went wrong. We are working hard to fix it.</ErrorMessage>
    </ErrorContainer>
  );
};

const ErrorContainer = styled.div`
  width: 100%;
  padding: 20px;
  background-color: #f8d7da; /* Red background color for error */
  border: 1px solid #f5c6cb; /* Red border color */
  border-radius: 5px;
  margin-bottom: 20px;
`;

const ErrorMessage = styled.p`
  text-align: center;
  color: #721c24; /* Dark red text color */
  font-size: 18px;
`;

export default ErrorComponent;
