import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

export default function ProjectBox({ id, title, description, img }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/project/${id}`);
  };

  return (
    <Wrapper>
      <ImgBtn className="animate pointer" onClick={handleClick}>
        <img
          className="radius8"
          src={img || "https://via.placeholder.com/400x200"}
          alt={title || "Project"}
          style={{ width: "376px", height: "309px", objectFit: "cover" }}
        />
      </ImgBtn>
      <h4 className="font20 extraBold">{title}</h4>
      <p className="font13">{description}</p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100% !important;
  margin-top: 30px !important;
  object-fit: cover;
  text-align: center;

  img {
    margin: 20px 0;
  }
  h3 {
    padding-bottom: 10px;
  }
`;

const ImgBtn = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  :hover > img {
    opacity: 0.5;
  }
`;
