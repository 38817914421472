// src/pages/ContactUsPage.js

import React, { useState } from 'react';
import styled from 'styled-components';
import ContactCard from 'components/ContactCard/ContactCard';
import CrimeReportForm from 'components/Forms/CrimeReportForm';
import FeedbackButton from 'components/Buttons/FeedbackButton';
import FeedbackForm from 'components/Forms/FeedbackForm';
import InputForm from 'components/Forms/InputForm';
import Footer from "components/Sections/Footer";

const ContactUsPage = () => {
  const [activeForm, setActiveForm] = useState(null);

  const toggleForm = (formName) => {
    setActiveForm(activeForm === formName ? null : formName);
  };

  return (
    <div style={{margin:44, padding:44}}>
        <Header>Contact Us</Header>
        <Subheader>We're Here to Assist and Listen</Subheader>
      <PageWrapper>
        <ContactSection>
            <ContactCard />
            <InfoSection>
            <h2>Why Reach Out?</h2>
            <p>At the Namibian Government Products Investment Fund (GPTF), we are committed to fostering growth and development. Whether you have inquiries about our investment initiatives, suggestions, or just want to learn more, we are here to assist you. Our team is dedicated to providing you with the best possible support and information.</p>
            <h3>What You Can Expect:</h3>
            <ExpectationsList>
                <li>Prompt Response Times</li>
                <li>Expert Guidance and Knowledgeable Staff</li>
                <li>Investment Solutions Tailored to Your Needs</li>
                <li>Transparent and Open Communication</li>
            </ExpectationsList>
            </InfoSection>
        </ContactSection>
        <FeedbackSection>
            <FeedbackButton onClick={() => toggleForm('input')} text="Share Your Ideas" description="We welcome your insights and ideas to help shape our future initiatives." />
            <FeedbackButton onClick={() => toggleForm('crime')} text="Report an Issue" description="Help us maintain the integrity of our investment programs." />
            <FeedbackButton onClick={() => toggleForm('feedback')} text="Provide Feedback" description="Your feedback is invaluable to us in improving our services." />
        </FeedbackSection>
        {activeForm === 'input' && <InputForm onClose={() => setActiveForm(null)} />}
        {activeForm === 'crime' && <CrimeReportForm onClose={() => setActiveForm(null)} />}
        {activeForm === 'feedback' && <FeedbackForm onClose={() => setActiveForm(null)} />}
      </PageWrapper>      
    </div>
)};


const PageWrapper = styled.div`
  max-width: 1000px;
  margin: 0px auto;
  padding: 40px;
  font-family: 'Poppins', sans-serif;
  background-color: #f0f8f0;
  color: #333;
  position: relative;
  overflow: hidden;
  height: 100vh;
  border-radius:12px;

  &::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: repeating-linear-gradient(
      45deg,
      #9bbb5910,
      #9bbb5910 10px,
      #f0f8f0 10px,
      #f0f8f0 20px
    );
    z-index: -1;
    animation: moveBackground 20s linear infinite;
  }

  @keyframes moveBackground {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-50px, -50px);
    }
  }
`;

const Header = styled.h1`
  text-align: center;
  color: #9bbb59;
  font-size: 4em;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 4px;
    background-color: #9bbb59;
    border-radius: 2px;
  }
`;

const Subheader = styled.p`
  text-align: center;
  font-size: 1.4em;
  margin-bottom: 40px;
  color: #666;
  font-weight: 300;
`;

const ContactSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  background-color: #fff;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(155, 187, 89, 0.2);
  transform: perspective(1000px) rotateX(5deg);
  transition: transform 0.3s ease;

  &:hover {
    transform: perspective(1000px) rotateX(0deg);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    transform: none;
  }
`;

const InfoSection = styled.div`
  width: 45%;
  
  h2, h3 {
    color: #9bbb59;
    margin-bottom: 20px;
  }

  p {
    line-height: 1.6;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 30px;
  }
`;

const ExpectationsList = styled.ul`
  list-style-type: none;
  padding-left: 0;

  li {
    position: relative;
    padding-left: 30px;
    margin-bottom: 15px;
    
    &::before {
      content: '✓';
      position: absolute;
      left: 0;
      color: #9bbb59;
      font-weight: bold;
    }
  }
`;

const FeedbackSection = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 60px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export default ContactUsPage;