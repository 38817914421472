import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";
import ErrorComponent from "../ErrorComponent/ErrorComponent";
import TestimonialSlider from "../Elements/TestimonialSlider";
import { getArticles } from '../../store/actions/cmsActions'

// Importing testimonials data
import testimonialsData from "../../data/testimonialsData";

export default function Blog() {
  const [testimonials, setTestimonials] = useState(testimonialsData);
  const [loadingTestimonials, setLoadingTestimonials] = useState(false);
  const [errorTestimonials, setErrorTestimonials] = useState(null);

  const navigate = useNavigate();

  // Go to full blog page
  const handleViewAll = () => {
    navigate('/blogs');
  };

  const dispatch = useDispatch();
  const { articles } = useSelector((state) => state.cmsReducer);

  const articleList = articles?.map((article, i) => {
    return(
      <div key={i} className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
        <BlogBox
          id={article?.id}
          title={article?.title}
          text={article?.description}
          tag="Community"
          author="GPTF"
          imageUrl={article?.imageUrl}
        />
      </div>
    );
  });

  const organizationId = 'OfQa6MDeh3aGmboGnbQJ';

  useEffect(() => {
    dispatch(getArticles(organizationId));
  }, [dispatch, organizationId]);

  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Latest News and Articles</h1>
            <p className="font13">
              Stay updated with the latest happenings and developments at the Game Products Trust Fund.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            {articleList}
          </div>
           
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px", cursor: 'pointer' }}>
              <FullButton title="View all" action={handleViewAll} />
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">What They Say?</h1>
            <p className="font13">
              Discover what others are saying about the Game Products Trust Fund and its impact on wildlife
              <br />
              conservation and rural development in Namibia.
            </p>
          </HeaderInfo>
          {loadingTestimonials ? (
            <LoadingMessage>Loading testimonials...</LoadingMessage>
          ) : errorTestimonials ? (
            <ErrorComponent message={errorTestimonials.message} />
          ) : (
            <TestimonialSlider testimonials={testimonials} />
          )}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;

const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;

const LoadingMessage = styled.p`
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
`;

const ErrorMessage = styled.p`
  text-align: center;
  color: red;
  font-size: 18px;
  margin-top: 20px;
`;
