import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Footer from "../Sections/Footer";

const SelectedProject = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { projects } = useSelector((state) => state.cmsReducer);
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("Projects:", projects);
    console.log("Current ID:", id);

    if (projects && projects.length > 0) {
      const foundProject = projects.find((a) => String(a.id) === String(id));
      console.log("Found Project:", foundProject);
      setProject(foundProject);
      setLoading(false);
    }
  }, [id, projects]);

  if (loading) {
    return <LoadingWrapper>Loading...</LoadingWrapper>;
  }

  if (!project) {
    return <LoadingWrapper>There are no projects to display.</LoadingWrapper>;
  }

  return (
    <div style={{ marginTop: 64}}>
    <Wrapper>
      <Container>
        <BackButton onClick={() => navigate('/projects')}>
          ← Back to Projects
        </BackButton>
        <Header>
          <Title>{project.title}</Title>
          <Meta>
            <Author>
              By GPTF Team
              {/* {project.createdBy} */}
              </Author>
            <DateWrapper>
              {project.created && new Date(project.created * 1000).toLocaleDateString()}
            </DateWrapper>
          </Meta>
        </Header>
        {project.imageUrl && (
          <ImageWrapper>
            <Image src={project.imageUrl} alt={project.title} />
          </ImageWrapper>
        )}
        <Content dangerouslySetInnerHTML={{ __html: project.content }} />
        <TagsWrapper>
          {project.tags && project.tags.map((tag, index) => (
            <Tag key={index}>{tag}</Tag>
          ))}
        </TagsWrapper>
        <BackButton onClick={() => navigate('/projects')}>
          ← Back to Projects
        </BackButton>
      </Container>
    </Wrapper>
    {/* <Footer />     */}
    </div>
  );
};


const Wrapper = styled.div`
  background-color: #f6f6f6;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  padding: 40px 20px;
`;

const Container = styled.div`
  max-width: 62%;
  width: 100%;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 40px;
  overflow: hidden;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.5s ease forwards;

  @keyframes fadeIn {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: #0070f3;
  font-size: 16px;
  cursor: pointer;
  padding: 0;
  margin-bottom: 20px;
  transition: color 0.3s ease;

  &:hover {
    color: #0050a3;
  }
`;

const Header = styled.header`
  margin-bottom: 40px;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
  line-height: 1.2;
`;

const Meta = styled.div`
  display: flex;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
`;

const Author = styled.span`
font-size: 16px;
   color: #333;
  font-style: italic;
  margin-bottom: 10px;
  line-height: 1.2;
  `;

const DateWrapper = styled.span`
 font-size: 16px;
   color: #333;
  font-style: italic;
  margin-bottom: 10px;
  line-height: 1.2;
`;

const ImageWrapper = styled.div`
  margin: -40px -40px 30px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const Content = styled.div`
  font-size: 18px;
  line-height: 1.6;
  color: #333;
`;

const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
`;

const Tag = styled.span`
  background-color: #f0f0f0;
  color: #333;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 14px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 24px;
  color: #333;
`;

export default SelectedProject;
