import React from 'react';
import styled from 'styled-components';
import Organization from 'components/Organogram/Organization';
import OrganizationalChart from 'components/Organogram/OrganizationalChart';
import Footer from "../components/Sections/Footer";

const OrganizationManagement = () => {
  return (
    <>
      <PageWrapper>
        <OrganizationalChart />
        <Organization />
      </PageWrapper>
    </>
  );
};

const PageWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

export default OrganizationManagement;
