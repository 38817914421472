import React, { useState } from 'react';
import styled from 'styled-components';

const CrimeReportForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    incidentType: '',
    date: '',
    time: '',
    location: '',
    description: '',
    witnessName: '',
    witnessContact: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Crime Report submitted:', formData);
    setFormData({
      incidentType: '',
      date: '',
      time: '',
      location: '',
      description: '',
      witnessName: '',
      witnessContact: '',
    });
    onClose();
  };

  return (
    <FormOverlay>
      <FormContainer>
        <h2>Report a Crime</h2>
        <form onSubmit={handleSubmit}>
          <Select 
            name="incidentType" 
            value={formData.incidentType} 
            onChange={handleChange} 
            required
          >
            <option value="">Select Incident Type</option>
            <option value="theft">Theft</option>
            <option value="assault">Assault</option>
            <option value="vandalism">Vandalism</option>
            <option value="other">Other</option>
          </Select>
          <Input 
            type="date" 
            name="date" 
            value={formData.date} 
            onChange={handleChange} 
            required 
          />
          <Input 
            type="time" 
            name="time" 
            value={formData.time} 
            onChange={handleChange} 
            required 
          />
          <Input 
            type="text" 
            name="location" 
            placeholder="Location of Incident" 
            value={formData.location} 
            onChange={handleChange} 
            required 
          />
          <TextArea 
            name="description" 
            placeholder="Detailed Description of the Incident" 
            value={formData.description} 
            onChange={handleChange} 
            required 
          />
          <Input 
            type="text" 
            name="witnessName" 
            placeholder="Witness Name (if any)" 
            value={formData.witnessName} 
            onChange={handleChange} 
          />
          <Input 
            type="tel" 
            name="witnessContact" 
            placeholder="Witness Contact Number" 
            value={formData.witnessContact} 
            onChange={handleChange} 
          />
          <Button type="submit">Submit Report</Button>
        </form>
        <CloseButton onClick={onClose}>&times;</CloseButton>
      </FormContainer>
    </FormOverlay>
  );
};

const FormOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const FormContainer = styled.div`
  background-color: #f0f0f0;
  padding: 20px;
  border: 2px solid #9bbb59;
  border-radius: 15px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  font-family: 'Courier New', Courier, monospace;
  transform: perspective(800px) rotateX(5deg) rotateY(-5deg);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;

  &:hover {
    transform: perspective(800px) rotateX(0deg) rotateY(0deg);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  }

  h2 {
    color: #9bbb59;
    text-align: center;
    margin-bottom: 20px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #9bbb59;
  border-radius: 5px;
  font-family: 'Courier New', Courier, monospace;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #9bbb59;
  border-radius: 5px;
  font-family: 'Courier New', Courier, monospace;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #9bbb59;
  border-radius: 5px;
  font-family: 'Courier New', Courier, monospace;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const Button = styled.button`
  background-color: #9bbb59;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #006666;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #9bbb59;
`;

export default CrimeReportForm;
