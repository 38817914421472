import React from 'react';
import styled from 'styled-components';
import { orgChartData } from '../../data/organogramData';

const OrganizationalChart = () => {
  const renderOrgNode = (node) => {
    return (
      <li key={node.name}>
        <div className="org-chart__item">
          <img src={node.image} alt={node.name} />
          <div className="org-chart__content">
            <h3>{node.name}</h3>
            <p>{node.title}</p>
          </div>
        </div>
        {node.children && node.children.length > 0 && (
          <ul>
            {node.children.map(renderOrgNode)}
          </ul>
        )}
      </li>
    );
  };

  return (
    <ChartWrapper>
      <h2 style={{marginTop:"60px"}}>Organizational Structure</h2>
      <ul className="org-chart">
        {renderOrgNode(orgChartData)}
      </ul>
    </ChartWrapper>
  );
};

const ChartWrapper = styled.div`
  margin-bottom: 50px;
  margin-top: 50x;

  h2 {
    text-align: center;
    margin-bottom: 30px;
  }

  .org-chart {
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding: 0;

    ul {
      padding-top: 20px;
      position: relative;
      transition: all 0.5s;
      
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        border-left: 1px solid #ccc;
        width: 0;
        height: 20px;
      }
    }

    li {
      float: left;
      text-align: center;
      list-style-type: none;
      position: relative;
      padding: 20px 5px 0 5px;
      transition: all 0.5s;

      &::before, &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 50%;
        border-top: 1px solid #ccc;
        width: 50%;
        height: 20px;
      }

      &::after {
        right: auto;
        left: 50%;
        border-left: 1px solid #ccc;
      }

      &:only-child::after, &:only-child::before {
        display: none;
      }

      &:first-child::before, &:last-child::after {
        border: 0 none;
      }

      &:last-child::before {
        border-right: 1px solid #ccc;
        border-radius: 0 5px 0 0;
      }

      &:first-child::after {
        border-radius: 5px 0 0 0;
      }
    }
  }

  .org-chart__item {
    border: 1px solid #ccc;
    padding: 10px;
    text-decoration: none;
    color: #666;
    font-size: 14px;
    display: inline-block;
    min-width: 150px;
    min-height: 120px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      object-fit: cover;
      margin-bottom: 10px;
    }

    .org-chart__content {
      h3 {
        margin: 0;
        font-size: 16px;
        color: #333;
      }

      p {
        margin: 5px 0 0;
        font-size: 14px;
        color: #666;
      }
    }
  }
`;

export default OrganizationalChart;