import { 
  FETCH_ARTICLES_SUCCESS, ON_NOTIFICATION, FETCH_CATEGORIES_SUCCESS, FETCH_PROJECTS_SUCCESS,
  FETCH_TENDERS_SUCCESS, FETCH_DOCUMENTS_SUCCESS
} from "../types/cmsTypes";

 
const initialState = {
  articles: [],
  categories: [],
  documents: [],
  menus: [],
  pages: [],
  storage: [],
  projects: [],
  tenders: [],
  
  // CRUD Operations
  article: null,
  category: null,
  menu: null,
  page: null,
  project: null,
  tender: null,
  fileUpload : null, 

  notification: null,

  error: null,
};

const cmsReducer = (state = initialState, action) => {
  switch (action.type) {
   
    case  FETCH_ARTICLES_SUCCESS:
      // Handle successful fetch
      return {
        ...state,
        articles: action.payload.articles,
        error: null,
      }; 
 
    case  FETCH_CATEGORIES_SUCCESS:
      // Handle successful fetch
      return {
        ...state,
        categories: action.payload.categories,
        error: null,
      };
 
    case  FETCH_PROJECTS_SUCCESS:
      // Handle successful fetch
      return {
        ...state,
        projects: action.payload.projects,
        error: null,
      };
  

      case FETCH_DOCUMENTS_SUCCESS:
        // Handle successful fetch
        return {
          ...state,
          storage: action.payload.storage,
          error: null,
        };

     
    case FETCH_TENDERS_SUCCESS:
      // Handle successful fetch
      return {
        ...state,
        tenders: action.payload.tenders,
        error: null,
      };
   
 

    case ON_NOTIFICATION:  
       return {
        ...state,
        notification: action.payload,
    };

    default:
      return state;
  }
};

export default cmsReducer;
