import React from "react";
import styled from "styled-components";
// Assets
import QuoteIcon from "../../assets/svg/Quotes";

export default function TestimonialBox({ text, author }) {
  return (
    <Wrapper className="darkBg radius8 flexNullCenter flexColumn">
      <QuoteWrapper>
        <QuoteIcon />
      </QuoteWrapper>
      <TextWrapper>
        <p className="whiteColor font13">
          {text}
        </p>
      </TextWrapper>
      <p className="orangeColor font13" style={{ alignSelf: 'flex-end', marginTop: 'auto' }}>
        <em>{author}</em>
      </p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height: 280px; 
  padding: 20px 30px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
`;

const QuoteWrapper = styled.div`
  position: relative;
  top: -40px;
`;

const TextWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; 
  -webkit-box-orient: vertical;
  margin-bottom: 30px;
  flex-grow: 1;
`;
