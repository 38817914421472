import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
// Assets
import LogoImg from "../../assets/svg/Logo";

export default function Footer() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <FooterWrapper>
      <FooterContent>
        <Link className="flexCenter animate pointer" to="home" smooth={true} offset={-80}>
          <LogoImg />
          <h1 className="font15 extraBold whiteColor" style={{ marginLeft: "15px" }}>
            GPTF
          </h1>
        </Link>
        <StyleP className="whiteColor font13">
          © {getCurrentYear()} - <span className="purpleColor font13">Loop Technologies</span> All Rights Reserved
        </StyleP>
        <Link className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80}>
          Back to top
        </Link>
      </FooterContent>
    </FooterWrapper>
  );
}

const FooterWrapper = styled.footer`
  background-color: #333;
  width: 100%;
  padding: 20px 0;
  color: white;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 1200px; /* Adjust to match your container width */
  padding: 0 20px;
  
  @media (max-width: 550px) {
    flex-direction: column;
    text-align: center;
  }
`;

const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
